import React, { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import moment from "moment";

import {
  checkrewardCriteria,
  getDashBoardDetails,
  getLevelTeam,
  getNewLevelData,
  getRewardData,
  getRewardTimer,
  leveWiseCount,
} from "../../../helpers/api";
import { useSelector } from "react-redux";
import { Loader } from "../Dashboard/Loader";
import CountdownTimer from "../../pages/CountDownTimer";
import { TbMoneybag } from "react-icons/tb";
import { GiGoldStack, GiLevelThree } from "react-icons/gi";
import { GrSteps } from "react-icons/gr";
import { RiStackshareLine } from "react-icons/ri";

const RewardStatus = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { userToken } = useSelector((state) => state.auth);
  const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
  const walletAddress = walletDetails?.wallet;
  const userId = userDetails?.userId;
  const [apidata, setApidata] = useState("");
  const [timertimeStamp, setTimerTimestamp] = useState("");
  const [userData, setUserData] = useState("");
  const [loading, setLoading] = useState(true);
  const [newData, setNewData] = useState("");
  useEffect(() => {
    const fetchTimer = () => {
      let formData = { userId: userId, packageId: userToken.value };
      getRewardTimer(formData)
        .then((res) => {
          setTimerTimestamp(res);
        })
        .catch((err) => {
          console.error("Error fetching timer:", err);
        });
    };

    fetchTimer();
    const formData = {
      packageId: userToken?.value,
      wallet_address: walletAddress,
    };

    getDashBoardDetails(formData).then((res) => {
      setUserData(res);
    });
  }, [userId, walletAddress, userToken]);

  useEffect(() => {
    let fData = {
      user: walletAddress,
      packageId: userToken.value,
    };
    checkrewardCriteria(fData).then((res) => {
      if (res) {
        setApidata(res?.data?.allleveldata);
      }
      setLoading(false);
    });
  }, [walletAddress, userToken]);

  useEffect(() => {
    const fetchTimer = () => {
      let formData = {
        user: walletAddress,
        packageId: userToken.value,
        time: userData.rewardDistributeTime,
      };
      getNewLevelData(formData)
        .then((res) => {
          setNewData(res?.data);
        })
        .catch((err) => {
          console.error("Error fetching timer:", err);
        });
    };

    fetchTimer();
  }, [walletAddress, userToken, userData]);

  return (
    <div>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card">
            <Tab.Container defaultActiveKey="All">
              <div className="card-header border-0 pb-2 flex-wrap">
                <h4 className="heading me-2">Monthly Reward Status</h4>
                <div className="deal-of-the-day-content mb-3 mt-0 d-flex justify-content-center align-items-center">
                  <CountdownTimer
                    timestamp={timertimeStamp?.date ? timertimeStamp?.date : 0}
                  />
                </div>
              </div>
              {userData?.rewardDistributeTime > 0 || apidata?.eligibe ? (
                <div className="card-body pt-2">
                  <div className="row justify-content-center">
                    <div className="col-xl-4  col-lg-4 col-sm-6">
                      <div className="card completed">
                        <div className="card-body">
                          <div className="media ai-icon">
                            <span className="bg-gradient-green">
                              <RiStackshareLine />
                            </span>
                            <div className="media-body">
                              <div className="">Level 1</div>
                              <div className="mt-1 fs-4 fw-bold">
                                STAR DMT Completed 🎉
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4  col-lg-4 col-sm-6">
                      <div className="card completed">
                        <div className="card-body">
                          <div className="media ai-icon">
                            <span className="bg-gradient-green">
                              <RiStackshareLine />
                            </span>
                            <div className="media-body">
                              <div className="">Level 2 </div>
                              <div className="mt-1 fs-4 fw-bold">
                                PLANET DMT Completed 🎉
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {apidata?.[3]?.totaTeam > 63 ? (
                      <div className="col-xl-4  col-lg-4 col-sm-6">
                        <div className="card   completed">
                          <div className="card-body">
                            <div className="media ai-icon">
                              <span className="bg-gradient-green">
                                <RiStackshareLine />
                              </span>
                              <div className="media-body">
                                <div className="">Level 3 </div>
                                <div className="mt-1 fs-4 fw-bold">
                                  SOLAR DMT Completed 🎉
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {apidata?.[4]?.totaTeam > 255 ? (
                      <div className="col-xl-4  col-lg-4 col-sm-6">
                        <div className="card   completed">
                          <div className="card-body">
                            <div className="media ai-icon">
                              <span className="bg-gradient-green">
                                <RiStackshareLine />
                              </span>
                              <div className="media-body">
                                <div className="">Level 4 </div>
                                <div className="mt-1 fs-4 fw-bold">
                                  GALAXY DMT Completed 🎉
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {apidata?.[5]?.totaTeam > 1000 ? (
                      <div className="col-xl-4  col-lg-4 col-sm-6">
                        <div className="card   completed">
                          <div className="card-body">
                            <div className="media ai-icon">
                              <span className="bg-gradient-green">
                                <RiStackshareLine />
                              </span>
                              <div className="media-body">
                                <div className="">Level 5 </div>
                                <div className="mt-1 fs-4 fw-bold">
                                  GALAXY DMT Completed 🎉
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {apidata?.[6]?.totaTeam > 4000 ? (
                      <div className="col-xl-4  col-lg-4 col-sm-6">
                        <div className="card   completed">
                          <div className="card-body">
                            <div className="media ai-icon">
                              <span className="bg-gradient-green">
                                <RiStackshareLine />
                              </span>
                              <div className="media-body">
                                <div className="">Level 6</div>
                                <div className="mt-1 fs-4 fw-bold">
                                  UNIVERSE DMT Completed 🎉
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* cureent level */}
                    <div className="col-xl-4  col-lg-4 col-sm-6">
                      <div className="card current">
                        <div className="card-body">
                          <div className="media ai-icon">
                            <span className="bg-gradient-orange">
                              <RiStackshareLine />
                            </span>
                            <div className="media-body">
                              <div className="">Current Level </div>
                              <div className="mt-1 fs-4 fw-bold">
                                {apidata?.[3]?.totaTeam < 63
                                  ? "3rd - SOLAR DMT "
                                  : apidata?.[3]?.totaTeam >= 62 &&
                                    apidata?.[4]?.totaTeam < 255
                                  ? "4th - GALAXY DMT"
                                  : apidata?.[4]?.totaTeam > 255 &&
                                    apidata?.[3]?.totaTeam > 63 &&
                                    apidata?.[5]?.totaTeam < 1000
                                  ? "Level 5:SUPERNOVA DMT"
                                  : apidata?.[5]?.totaTeam > 1000 &&
                                    apidata?.[4]?.totaTeam > 255 &&
                                    apidata?.[3]?.totaTeam > 63
                                  ? "Level 6: UNIVERSE DMT"
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="badge-container mb-3">
                    <span className="badge bg-primary mt-2 m-2 w-100">Level 1: STAR DMT Completed</span>
                    <span className="badge bg-primary mt-2 m-2 w-100">Level 2: PLANET DMT Completed🎉</span>
                    {apidata?.[3]?.totaTeam > 63 ? <span className="badge bg-primary mt-2 m-2 w-100">Level 3:  SOLAR DMT Completed  🎉</span> : ''}
                    {apidata?.[4]?.totaTeam > 255 ? <span className="badge bg-primary mt-2 m-2 w-100">Level 4:  GALAXY DMT Completed  🎉</span> : ''}
                    {apidata?.[5]?.totaTeam > 1000 ? <span className="badge bg-primary mt-2 m-2 w-100">Level 5:  SUPERNOVA DMT Completed  🎉</span> : ''}
                    {apidata?.[6]?.totaTeam > 4000 ? <span className="badge bg-primary mt-2 m-2 w-100">Level 6:  UNIVERSE DMT Completed  🎉</span> : ''}

                    {apidata?.[3]?.totaTeam > 53 && apidata?.[3]?.totaTeam < 63 ? <span className="badge bg-warning mt-2 m-2 w-100">Level 3:  SOLAR DMT  Pending</span> : ''}
                    {apidata?.[4]?.totaTeam >225 && apidata?.[4]?.totaTeam <255 ? <span className="badge bg-warning mt-2 m-2 w-100">Level 4:  GALAXY DMT  Pending</span> : ''}
                    {apidata?.[4]?.totaTeam >901 && apidata?.[5]?.totaTeam < 1000 ? <span className="badge bg-warning mt-2 m-2 w-100">Level 5:  SUPERNOVA DMT  Pending</span> : ''}
                    {apidata?.[6]?.totaTeam > 3700 &&  apidata?.[6]?.totaTeam <  4000 ? <span className="badge bg-warning mt-2 m-2 w-100">Level 6:  UNIVERSE DMT  Pending</span> : ''}
                    <span className="badge bg-danger  mt-2 m-2 w-100">Current Level : <span className="fw-bolder">{apidata?.[3]?.totaTeam < 63 ? "3rd - SOLAR DMT " : apidata?.[3]?.totaTeam >= 62 && apidata?.[4]?.totaTeam < 255 ? '4th - GALAXY DMT' : apidata?.[4]?.totaTeam > 255 && apidata?.[3]?.totaTeam > 63 && apidata?.[5]?.totaTeam < 1000 ? 'Level 5:SUPERNOVA DMT' : apidata?.[5]?.totaTeam > 1000 && apidata?.[4]?.totaTeam > 255 && apidata?.[3]?.totaTeam > 63 ? 'Level 6: UNIVERSE DMT' : ''} </span></span>

                  </div> */}

                  <div className="row mt-5">
                    <div
                      id="history_wrapper"
                      className="table-responsive dataTablehistory"
                    >
                      {loading ? (
                        <Loader />
                      ) : (
                        <div className="dataTables_wrapper no-footer">
                          <h6>
                            Congratulations, you are eligible for MONTHLY
                            REWARDS 🎖️
                          </h6>
                          {apidata?.[3]?.totaTeam < 55 ? (
                            <div className="card border">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div class="card bg-lgreen">
                                      <img
                                        src="/images/rewards.png"
                                        style={{
                                          height: "143px",
                                          objectFit: "contain",
                                        }}
                                        alt=""
                                        class="img-fluid "
                                      />
                                      <div class="card-body bg-lighter">
                                        <h6 class="card-title">
                                          {" "}
                                          <em className="text-success">
                                            {" "}
                                            Congratulations,
                                          </em>{" "}
                                          <br />
                                          you are eligible for Monthly Rewards
                                          🎖️
                                        </h6>
                                        <div className="text-warning">
                                          {" "}
                                          Current Month Target{" "}
                                        </div>
                                        <p>
                                          Each part requires 3 IDs × 4 parts =
                                          12 IDs
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-8">
                                    <div className="project-box bg-light-orange">
                                      <div class="d-flex justify-content-between align-items-center">
                                        <div className="fw-bold ">
                                          Month Status
                                        </div>
                                        <div className="">
                                          {" "}
                                          {userData?.rewardMonthStatus &&
                                          userData?.levelReward == 3
                                            ? "🟢 Completed"
                                            : "🟠 Inprogress"}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div class="col-md-6 col-xxl-6">
                                        <div class="project-box">
                                          <h4 class="f-w-500  text-success">
                                            Current Status
                                          </h4>

                                          <div class="details row">
                                            <div class="col-6">
                                              <span>A part: </span>
                                            </div>
                                            <div class="font-danger col-6">
                                              <strong>
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[2]?.[0]?.month_part1
                                                  : newData[2]?.[0]?.part1}
                                              </strong>
                                            </div>
                                            <div class="col-6">
                                              <span>B part:</span>
                                            </div>
                                            <div class="font-danger col-6">
                                              <strong>
                                                {" "}
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[2]?.[1]?.month_part2
                                                  : newData[2]?.[1]?.part2}
                                              </strong>
                                            </div>
                                            <div class="col-6">
                                              <span>C part:</span>
                                            </div>
                                            <div class="font-danger col-6">
                                              <strong>
                                                {" "}
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[2]?.[2]?.month_part3
                                                  : newData[2]?.[2]?.part3}
                                              </strong>
                                            </div>
                                            <div class="col-6">
                                              <span>D part:</span>
                                            </div>
                                            <div class="font-danger col-6">
                                              {" "}
                                              <strong>
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[2]?.[3]?.month_part4
                                                  : newData[2]?.[3]?.part4}
                                              </strong>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-6 col-xxl-6">
                                        <div class="project-box">
                                          <h4 class="f-w-500  text-danger">
                                            Target Members
                                          </h4>

                                          <div class="details row">
                                            <div class="col-6">
                                              <span>A part: </span>
                                            </div>
                                            <div class="font-danger col-6">
                                              <strong>3</strong>
                                            </div>
                                            <div class="col-6">
                                              <span>B part:</span>
                                            </div>
                                            <div class="font-danger col-6">
                                              <strong> 3</strong>
                                            </div>
                                            <div class="col-6">
                                              <span>C part:</span>
                                            </div>
                                            <div class="font-danger col-6">
                                              <strong> 3</strong>
                                            </div>
                                            <div class="col-6">
                                              <span>D part:</span>
                                            </div>
                                            <div class="font-danger col-6">
                                              {" "}
                                              <strong>3</strong>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mt-4">
                                    <div class="col-lg-12 col-xxl-12">
                                      <h4 class="heading">
                                        Level 3rd PartWise Team
                                      </h4>
                                      <div class="card card_image_bg widget-stat card-date-main pb-3 shadow-sm border">
                                        <div className="custom-padding">
                                          <h4 className="text-success">
                                            Available Members
                                          </h4>

                                          <div class="d-flex gap-5">
                                            <div>
                                              <span>A part: </span>
                                              <strong>
                                                {" "}
                                                {16 - newData[2]?.[0]?.part1} /
                                                16
                                              </strong>
                                            </div>

                                            <div>
                                              <span>B part:</span>
                                              <strong>
                                                {" "}
                                                {16 - newData[2]?.[1]?.part2} /
                                                16
                                              </strong>
                                            </div>

                                            <div>
                                              <span>C part:</span>{" "}
                                              <strong>
                                                {16 - newData[2]?.[2]?.part3} /
                                                16
                                              </strong>
                                            </div>

                                            <div>
                                              <span>D part:</span>{" "}
                                              <strong>
                                                {" "}
                                                {16 - newData[2]?.[3]?.part4} /
                                                16
                                              </strong>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {apidata?.[4]?.totaTeam < 255 &&
                          apidata?.[3]?.totaTeam > 56 ? (
                            <>
                              <div className="row">
                                <div class="col-lg-4">
                                  <div class="card bg-lgreen">
                                    <img
                                      src="/images/rewards.png"
                                      alt=""
                                      class="img-fluid reward_img"
                                    />
                                    <div class="card-body bg-lighter">
                                      <h6 class="card-title">
                                        {" "}
                                        <em class="text-success">
                                          {" "}
                                          Congratulations,
                                        </em>{" "}
                                        <br />
                                        you are eligible for Monthly Rewards 🎖️
                                      </h6>
                                      <div class="text-warning">
                                        {" "}
                                        Current Month Target{" "}
                                      </div>
                                      <p>
                                        Each part requires 8 IDs × 4 parts = 32
                                        IDs
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-8">
                                  <div className="project-box bg-light-orange">
                                    <div class="d-flex justify-content-between align-items-center">
                                      <div className="fw-bold ">
                                        Month Status
                                      </div>
                                      <div className="">
                                        {userData?.rewardMonthStatus &&
                                        userData?.levelReward == 4
                                          ? "🟢 Completed"
                                          : "🟠 Inprogress"}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="project-box">
                                        {/* To Be Completed Section */}
                                        <div className="d-row">
                                          <h4 class="f-w-500  text-success">
                                            Current Status
                                          </h4>
                                          <div class="details row">
                                            <div className="col-6">A part:</div>
                                            <div className="col-6">
                                              <strong>
                                                {" "}
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[3]?.[0]?.month_part1
                                                  : newData[3]?.[0]?.part1}
                                              </strong>
                                            </div>

                                            <div className="col-6">B part:</div>
                                            <div className="col-6">
                                              {" "}
                                              <strong>
                                                {" "}
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[3]?.[1]?.month_part2
                                                  : newData[3]?.[1]?.part2}
                                              </strong>
                                            </div>

                                            <div className="col-6">C part:</div>
                                            <div className="col-6">
                                              <strong>
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[3]?.[2]?.month_part3
                                                  : newData[3]?.[2]?.part3}
                                              </strong>
                                            </div>

                                            <div className="col-6">D part:</div>
                                            <div className="col-6">
                                              <strong>
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[3]?.[3]?.month_part4
                                                  : newData[3]?.[3]?.part4}
                                              </strong>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-6">
                                      <div className="project-box">
                                        <h4 class="fw-500  text-danger">
                                          Target Members
                                        </h4>
                                        <div class="details row">
                                          <div>
                                            <strong>A part:</strong> 08
                                          </div>
                                          <div>
                                            <strong>B part:</strong> 08
                                          </div>
                                          <div>
                                            <strong>C part:</strong> 08
                                          </div>
                                          <div>
                                            <strong>D part:</strong> 08
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-12 mt-4">
                                <h5 className="card-title mb-3">
                                  Level 4th PartWise Team{" "}
                                </h5>
                                <div className="card card_image_bg widget-stat card-date-main pb-3 shadow-sm border">
                                  <div className="card-body">
                                    <h4 className="text-success">
                                      Available Members
                                    </h4>
                                    <div className="d-flex gap-5">
                                      <div>
                                        <span>A part:</span>
                                        <strong>
                                          {" "}
                                          {64 - newData[3]?.[0]?.part1} / 64
                                        </strong>
                                      </div>
                                      <div>
                                        <span>B part:</span>
                                        <strong>
                                          {" "}
                                          {64 - newData[3]?.[1]?.part2} / 64
                                        </strong>
                                      </div>
                                      <div>
                                        <span>C part:</span>
                                        <strong>
                                          {64 - newData[3]?.[2].part3} / 64
                                        </strong>
                                      </div>
                                      <div>
                                        <span>D part:</span>
                                        <strong>
                                          {" "}
                                          {64 - newData[3]?.[3]?.part4} / 64
                                        </strong>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {apidata?.[5]?.totaTeam < 1000 &&
                          apidata?.[4]?.totaTeam > 235 &&
                          apidata?.[3]?.totaTeam >= 56 ? (
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div class="col-lg-4">
                                    <div class="card bg-lgreen">
                                      <img
                                        src="/images/rewards.png"
                                        alt=""
                                        class="img-fluid reward_img"
                                      />
                                      <div class="card-body bg-lighter">
                                        <h6 class="card-title">
                                          {" "}
                                          <em class="text-success">
                                            {" "}
                                            Congratulations,
                                          </em>{" "}
                                          <br />
                                          you are eligible for Monthly Rewards
                                          🎖️
                                        </h6>
                                        <div class="text-warning">
                                          {" "}
                                          Current Month Target{" "}
                                        </div>
                                        <p>
                                          Each part requires 25 IDs × 4 parts =
                                          100 IDs
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-8">
                                    <div className="project-box bg-light-orange">
                                      <div class="d-flex justify-content-between align-items-center">
                                        <div className="fw-bold ">
                                          Month Status
                                        </div>
                                        <div className="">
                                          STATUS :{" "}
                                          {userData?.rewardMonthStatus &&
                                          userData?.levelReward == 5
                                            ? "🟢 Completed"
                                            : "🟠 Inprogress"}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="project-box">
                                          <h4 class="fw-500  text-success">
                                            Current Status
                                          </h4>
                                          <div class="details row">
                                            <div className="col-6">
                                              <strong>A part:</strong>
                                            </div>
                                            <div className="col-6">
                                              <strong>
                                                {" "}
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[4]?.[0]?.month_part1
                                                  : newData[4]?.[0]?.part1}
                                              </strong>
                                            </div>

                                            <div className="col-6">
                                              <span>B part:</span>
                                            </div>
                                            <div className="col-6">
                                              <strong>
                                                {" "}
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[4]?.[1]?.month_part2
                                                  : newData[4]?.[1]?.part2}
                                              </strong>
                                            </div>

                                            <div className="col-6">
                                              <span>C part:</span>
                                            </div>
                                            <div className="col-6">
                                              <strong>
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[4]?.[2]?.month_part3
                                                  : newData[4]?.[2]?.part3}
                                              </strong>
                                            </div>

                                            <div className="col-6">
                                              <span>D part:</span>
                                            </div>
                                            <div className="col-6">
                                              <strong>
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[4]?.[3]?.month_part4
                                                  : newData[4]?.[3]?.part4}
                                              </strong>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="project-box">
                                          <h4 class="fw-500  text-danger">
                                            Target Members
                                          </h4>

                                          <div className="details row">
                                            <div className="col-6">
                                              <span>A part:</span>
                                            </div>
                                            <div className="col-6">
                                              <strong>25</strong>
                                            </div>

                                            <div className="col-6">
                                              <span>B part:</span>{" "}
                                            </div>
                                            <div className="col-6">
                                              <strong>25</strong>
                                            </div>

                                            <div className="col-6">
                                              <span>C part:</span>
                                            </div>
                                            <div className="col-6">
                                              <strong>25</strong>
                                            </div>

                                            <div className="col-6">
                                              <span>D part:</span>
                                            </div>
                                            <div className="col-6">
                                              <strong>25</strong>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-12 mt-3">
                                  <h5 className="heading mb-3">
                                    Level 5th PartWise Team
                                  </h5>

                                  <div className="card card_image_bg widget-stat card-date-main pb-3 shadow-sm border">
                                    <div className="card-body">
                                      <h4 className="text-success">
                                        Available Members
                                      </h4>
                                      <div className="d-flex gap-5">
                                        <div>
                                          <span>A part:</span>
                                          <strong>
                                            {" "}
                                            {256 - newData[4]?.[0]?.part1} / 256
                                          </strong>
                                        </div>
                                        <div>
                                          <span>B part:</span>

                                          <strong>
                                            {" "}
                                            {256 - newData[4]?.[1]?.part2} / 256
                                          </strong>
                                        </div>
                                        <div>
                                          <span>C part:</span>

                                          <strong>
                                            {" "}
                                            {256 - newData[4]?.[2]?.part3} / 256
                                          </strong>
                                        </div>
                                        <div>
                                          <span>D part:</span>
                                          <strong>
                                            {256 - newData[4]?.[3]?.part4} / 256
                                          </strong>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {apidata?.[6]?.totaTeam < 4000 &&
                          apidata?.[5]?.totaTeam > 925 &&
                          apidata?.[4]?.totaTeam >= 255 &&
                          apidata?.[3]?.totaTeam >= 63 ? (
                          <>
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div class="col-lg-4">
                                    <div class="card bg-lgreen">
                                      <img
                                        src="/images/rewards.png"
                                        alt=""
                                        class="img-fluid reward_img"
                                      />
                                      <div class="card-body bg-lighter">
                                        <h6 class="card-title">
                                          {" "}
                                          <em class="text-success">
                                            {" "}
                                            Congratulations,
                                          </em>{" "}
                                          <br />
                                          you are eligible for Monthly Rewards
                                          🎖️
                                        </h6>
                                        <div class="text-warning">
                                          {" "}
                                          Current Month Target{" "}
                                        </div>
                                        <p>
                                          Each part requires 75 IDs × 4 parts =
                                          300 IDs
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-8">
                                    <div className="project-box bg-light-orange">
                                      <div class="d-flex justify-content-between align-items-center">
                                        <div className="fw-bold ">
                                          Month Status
                                        </div>
                                        <div className="">
                                          STATUS :{" "}
                                          {userData?.rewardMonthStatus &&
                                          userData?.levelReward == 6
                                            ? "🟢 Completed"
                                            : "🟠 Inprogress"}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="project-box">
                                          <h4 class="fw-500  text-success">
                                            Current Status
                                          </h4>
                                          <div class="details row">
                                            <div className="col-6">
                                              <strong>A part:</strong>
                                            </div>
                                            <div className="col-6">
                                              <strong>
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[5]?.[0]?.month_part1
                                                  : newData[5]?.[0]?.part1}
                                              </strong>
                                            </div>

                                            <div className="col-6">
                                              <span>B part:</span>
                                            </div>
                                            <div className="col-6">
                                              <strong>
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[5]?.[1]?.month_part2
                                                  : newData[5]?.[1]?.part2}
                                              </strong>
                                            </div>

                                            <div className="col-6">
                                              <span>C part:</span>
                                            </div>
                                            <div className="col-6">
                                              <strong>
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[5]?.[2]?.month_part3
                                                  : newData[5]?.[2]?.part3}
                                              </strong>
                                            </div>

                                            <div className="col-6">
                                              <span>D part:</span>
                                            </div>
                                            <div className="col-6">
                                              <strong>
                                                {userData?.rewardDistributeTime >
                                                0
                                                  ? newData[5]?.[3]?.month_part4
                                                  : newData[5]?.[3]?.part4}
                                              </strong>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="project-box">
                                          <h4 class="fw-500  text-danger">
                                            Target Members
                                          </h4>

                                          <div className="details row">
                                            <div className="col-6">
                                              <span>A part:</span>
                                            </div>
                                            <div className="col-6">
                                              <strong>75</strong>
                                            </div>

                                            <div className="col-6">
                                              <span>B part:</span>{" "}
                                            </div>
                                            <div className="col-6">
                                              <strong>75</strong>
                                            </div>

                                            <div className="col-6">
                                              <span>C part:</span>
                                            </div>
                                            <div className="col-6">
                                              <strong>75</strong>
                                            </div>

                                            <div className="col-6">
                                              <span>D part:</span>
                                            </div>
                                            <div className="col-6">
                                              <strong>75</strong>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-12 mt-3">
                                  <h5 className="heading mb-3">
                                    Level 6th PartWise Team
                                  </h5>

                                  <div className="card card_image_bg widget-stat card-date-main pb-3 shadow-sm border">
                                    <div className="card-body">
                                      <h4 className="text-success">
                                        Available Members
                                      </h4>
                                      <div className="d-flex gap-5">
                                        <div>
                                          <span>A part:</span>
                                          <strong>
                                            {1024 - newData[5]?.[0].part1} /
                                            1024
                                          </strong>
                                        </div>
                                        <div>
                                          <span>B part:</span>

                                          <strong>
                                            {1024 - newData[5]?.[1].part2} /
                                            1024
                                          </strong>
                                        </div>
                                        <div>
                                          <span>C part:</span>

                                          <strong>
                                            {1024 - newData[5]?.[2].part3} /
                                            1024
                                          </strong>
                                        </div>
                                        <div>
                                          <span>D part:</span>
                                          <strong>
                                            {1024 - newData[5]?.[3].part4} /
                                            1024
                                          </strong>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                          ) : (
                            ""
                          )}
                          <div className="pt-3">
                            <span className="fs-4 fw-bolder"> Note: </span>
                            <ul style={{ listStyleType: "circle" }}>
                              <li className="text-muted">
                                If the current members are less than the target,
                                the user can be promoted to a new level.
                                However, the level is considered completed only
                                when the total required members for that level
                                are achieved.
                              </li>
                              <li className="text-danger">
                                Monthly rewards are given once per month.
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center mt-4 mb-2 fw-bold fs-5 dash-heading">
                  No Data Found.
                </div>
              )}
            </Tab.Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardStatus;
