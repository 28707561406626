import { CgProfile } from "react-icons/cg";
import React, { useEffect, useRef, useState } from "react";
import logo from "../../Asset 2.png";
import video1 from "./logovideo.mp4";
import { Link, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { GiConfirmed } from "react-icons/gi";
import { IoCopyOutline } from "react-icons/io5";
import Marquee from "react-fast-marquee";
import { getAllUserId } from "../../helpers/api";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

export const LoginDashBoard = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const email = userDetails?.email;
  const userId = userDetails?.userId;
  const popoverRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [alluserId, setUserId] = useState("");

  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);
  const copyReferral = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const handleLogout = () => {
    localStorage.removeItem("userDetails"); // Clear user details from local storage
    navigate("/login");
  };

  useEffect(() => {
    getAllUserId(email).then((res) => [setUserId(res)]);

    // Initialize the popover using Bootstrap's JavaScript API
    const popover = new window.bootstrap.Popover(popoverRef.current, {
      container: "body",
      html: true,
      placement: "bottom",
      boundary: "viewport",
      fallbackPlacements: ["top", "left", "right"],
      content: `
        <div class="">
           <div class="py-2 fw-bold">${email}</div>
           <hr/>
          <div class="d-grid"><a href="" class="btn logout-btn py-2">Logout</a></div>  
        </div>
      `,
    });

    // Handle the click event for the logout button inside the popover
    document.addEventListener("click", (e) => {
      if (e.target.classList.contains("logout-btn")) {
        handleLogout();
      }
    });

    return () => {
      popover.dispose();
    };
  }, [email]);
  const handleOptionChange = (event) => {
    const { value, selectedOptions } = event.target;
    setSelectedOption(value);
    localStorage.setItem(
      "userDetails",
      JSON.stringify({
        userId: value,
        email: email,
      })
    );
  };

  return (
    <div>
      <div className="navbar-area">
        <div className="">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light d-flex justify-content-between">
              <Link to="/" className="logo">
                <img src={logo} alt="" className="dash-logo" />
              </Link>
              <div className="d-flex align-items-center">
                <div className="me-2 ms-2">
                  <select
                    // defaultValue={"option"}
                    className="form-select custom-form-select border-0"
                    value={userId}
                    onChange={(e) => {
                      handleOptionChange(e);
                    }}
                  >
                    {alluserId &&
                      alluserId?.map((res, i) => {
                        return (
                          <option value={res?.userId} name={res?.userId}>
                            {res?.userId}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="ms-2 pointer-cursor"
                  ref={popoverRef}
                  data-bs-container="body"
                  data-bs-toggle="popover"
                  data-bs-placement="bottom"
                  data-bs-content="Bottom popover"
                >
                  <CgProfile size={35} color="#fff" />
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="plan-row">
        <div className="container ">
          <div className="page-spacer--">
            <h1 className="main-heading">
              Decentralized Autonomous Organization
            </h1>
            <h4 className="text-center pb-3">
              DMT Club - Blockchain-Powered Gift Distribution.
            </h4>
            <div className="row mt-3">
              <div className="col-lg-6 m-auto">
                <div className="input-group mb-2">
                  <input
                    id="copy"
                    type="text"
                    className="form-control py-3 border-0"
                    value={`${userId}`}
                    readOnly
                  />
                  <span className="input-group-text bg-dgreen">
                    <CopyToClipboard text={`${userId}`} onCopy={copyReferral}>
                      {isCopied ? (
                        <GiConfirmed size={25} />
                      ) : (
                        <IoCopyOutline size={25} />
                      )}
                    </CopyToClipboard>
                  </span>
                </div>
                <div className="fs-14 text-muted text-center">
                  Please Copy and Save UserID for further Login
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="page-spacer about-area bg-image">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12">
              <div className="card">
              <div className="card-body bg-black">
                <div className="ratio ratio-1x1">
                  <video src={video1} loop muted autoPlay></video>
                </div>
              </div>
              </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="about-text">
                  <span className="sub-title">About Us</span>
                  <h2>
                    A Group with a Common Passion: We are the Dream Makers Team
                    Club
                  </h2>
                  <p>
                    The DMT Club is the world’s first and largest crypto gift
                    donation club
                  </p>
                  <h6 className="mb-0">Permanent Membership Benefits:</h6>
                  <ul className="features-text">
                    <li className="">
                      <span>
                        <i className="bx bx-check-double"></i> Once a member
                        achieves permanent membership, they will enjoy monthly
                        benefits without any additional targets or criteria.
                      </span>
                    </li>
                    <li className=" mt-2">
                      <span>
                        <i className="bx bx-check-double "></i> This serves as a
                        retirement benefit with substantial monthly crypto
                        earnings.
                      </span>
                    </li>
                    <li className=" mt-2">
                      <span>
                        <i className="bx bx-check-double "></i> Our platform
                        ensures security by maintaining a peer-to-peer (P2P)
                        donation system
                      </span>
                    </li>
                  </ul>

                  <div className="mt-5">
                    <a href="/plan" className="btn-action2 py-2">
                      View Plan <MdKeyboardDoubleArrowRight />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="page-spacer">
          <div className="container video">
            <div className="col-lg-8 m-auto">
              <div className="card">
                <div className="card-body bg-black">
                  <div className="ratio ratio-21x9 mb-5">
                    <iframe
                      // width="500"
                      // height="500"
                      src="https://www.youtube.com/embed/VP525rw7tHA"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
