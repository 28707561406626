import React, { useEffect, useState } from "react";
import { FaCoins, FaRegEye } from "react-icons/fa";
import { GiGrowth, GiLevelThreeAdvanced } from "react-icons/gi";

import { FaRegUser } from "react-icons/fa";
import { FiCalendar, FiUser } from "react-icons/fi";
import { TbMoneybag } from "react-icons/tb";

import moment from "moment/moment";
import "aos/dist/aos.css";

import { IoCopyOutline, IoGiftOutline, IoWalletOutline } from "react-icons/io5"; // Import both filled and outline icons
import { CopyToClipboard } from "react-copy-to-clipboard";
import { GiConfirmed } from "react-icons/gi";
import {
  detailByEmail,
  getDashBoardDetails,
  getRewardTimer,
} from "../../../helpers/api";
import { Loader } from "./Loader";
import CountdownTimer from "../../pages/CountDownTimer";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RewardTimer from "../../pages/RewardTimer";
import { BiCoinStack } from "react-icons/bi";

const Home = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { userToken } = useSelector((action) => {
    return action.auth;
  });

  console.log(userToken.value, "userToken value ");
  const userId = userDetails?.userId;
  const [timertimeStamp, setTimerTimestamp] = useState("");
  const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
  const walletAddress = walletDetails?.wallet;
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedWallet, setIsCopiedWallet] = useState(false);

  const copyReferral = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const copyWallet = () => {
    setIsCopiedWallet(true);
    setTimeout(() => {
      setIsCopiedWallet(false);
    }, 2000);
  };
  const [loader, setLoader] = useState(true);
  const [userData, setUserData] = useState();
  const [userDashData, setUserDashData] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (walletAddress) {
      const formData = {
        packageId: userToken?.value,
        wallet_address: walletAddress,
      };
      getDashBoardDetails(formData).then((res) => {
        setUserDashData(res);
      });
    }

    const timeoutId = setTimeout(() => {
      const formData = {
        packageId: userToken?.value,
        userId: userId,
      };

      detailByEmail(formData).then((res) => {
        localStorage.setItem(
          "walletDetails",
          JSON.stringify({
            wallet: res?.data?.user,
          })
        );
        setLoader(false);
        setUserData(res?.data);
      });
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [walletAddress, userId, refresh, userToken]);

  useEffect(() => {
    const fetchTimer = () => {
      let formData = { userId: userId, packageId: userToken.value };
      getRewardTimer(formData)
        .then((res) => {
          setTimerTimestamp(res);
        })
        .catch((err) => {
          console.error("Error fetching timer:", err);
        });
    };

    fetchTimer();
  }, [userId, userToken]);

  return (
    <>
      <div className="row">
        {loader ? (
          <Loader />
        ) : (
          <>
            <div className="mb-2">
              <div className="card widget-stat  card-id-main">
                <div className="card-body ">
                  <div className="row">
                    <div className="col-lg-9">
                      <h3>
                        <div>
                          Monthly Reward Eligibilty Criteria Remaining Time{" "}
                          <Link to="/exploreReward">
                            <button
                              className="btn btn-xxs btn-success"
                              style={{ marginTop: "0px" }}
                            >
                              Explore More
                            </button>
                          </Link>{" "}
                        </div>
                      </h3>
                      <p className="text_gray">
                        {timertimeStamp?.type == "reward" ||
                        userData?.rewardEligible
                          ? "Fullfill monthly reward criteria within timer"
                          : "Fullfill monthly eligibilty reward criteria within 15 Days memberships joining"}
                      </p>
                    </div>
                    <div className="col-lg-3">
                      <div className="deal-of-the-day-content d-flex justify-content-center align-items-center">
                        {/* {timertimeStamp?.type == 'reward' ? <RewardTimer timestamp={timertimeStamp?.date ? timertimeStamp?.date : 0} /> : <CountdownTimer timestamp={timertimeStamp?.date ? timertimeStamp?.date : 0} />} */}

                        <CountdownTimer
                          timestamp={
                            timertimeStamp?.date ? timertimeStamp?.date : 0
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card widget-stat  card-id-main">
                <div className="card-body ">
                  <div className="row">
                    <div className="col-md-4">
                      <div className=" py-2">
                        <h2 className="heading  text-black">Referral Code</h2>
                      </div>
                    </div>
                    <div className="col-md-4"> </div>

                    <div className="col-md-4">
                      <div className="input-group">
                        <input
                          id="copy"
                          type="text"
                          className="form-control custom_inpt_form"
                          value={`https://dmtclubapp.com/register?user_id=${userData?.userId}`}
                          readOnly
                        />

                        <span className="input-group-text bg-black">
                          <CopyToClipboard
                            text={`https://dmtclubapp.com/register?user_id=${userData?.userId}`}
                            onCopy={copyReferral}
                          >
                            <button
                              className="bg-transparent border-0 banner-btn btn-refral"
                              type="button"
                            >
                              {isCopied ? (
                                <GiConfirmed size={22} />
                              ) : (
                                <IoCopyOutline size={22} />
                              )}
                            </button>
                          </CopyToClipboard>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-12 mt-2 ">
              <div className="row">
                <div className="col-xl-4  col-lg-4 col-sm-6">
                  <div className="card card_image_bg widget-stat  card-id-main">
                    <div className="custom-padding">
                      <div className="media">
                        <div className="media-body">
                          <div className="text-grey">User ID</div>
                          <div className="mt-1 fs-4 fw-bold">
                            {userData?.userId}
                          </div>

                          <span className="bg-gradient-green">
                            <FiUser className="fs-2" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4  col-lg-4 col-sm-6">
                  <div className="card card_image_bg widget-stat card-date-main">
                    <div className="custom-padding">
                      <div className="media">
                        <span className="bg-gradient-green">
                          <FiCalendar />
                        </span>
                        <div className="media-body">
                          <div className="text-grey">
                            My Joining Date & Time
                          </div>
                          <div className="mt-1 fs-4 fw-bold">
                            {moment(userData?.createdAt).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4  col-lg-4 col-sm-6">
                  <div className="card card_image_bg widget-stat card-level-main  ">
                    <div className="custom-padding">
                      <div className="media ai-icon">
                        <span className="bg-gradient-green">
                          <TbMoneybag />
                        </span>
                        <div className="media-body">
                          <div className="text-grey">Received Donation </div>
                          <div className="mt-1 fs-4 fw-bold">
                            {userDashData?.totalIncome
                              ? userDashData?.totalIncome
                              : 0}{" "}
                            USDT
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-6">
                  <div className="card card_image_bg widget-stat card-staking-main">
                    <div className="custom-padding">
                      <div className="media">
                        <span className="bg-gradient-green">
                          <BiCoinStack />
                        </span>
                        <div className="media-body">
                          <div className="text-grey">My Donation </div>
                          <div className="d-flex gap-2 align-items-center">
                            <div className="mt-1 fs-4 fw-bold">
                              {" "}
                              {userData?.stake_amount
                                ? userData?.stake_amount
                                : 0}{" "}
                              USDT
                            </div>{" "}
                            <Link to="/certificate" className="text-orange">
                             
                              <span className="fs-5"> view</span> <FaRegEye className="fs-4" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-sm-6">
                  <div
                    className="card_image_bg widget-stat card "
                    //style={{ background: "#7445cc" }}
                  >
                    <div className="custom-padding">
                      <div className="media">
                        <span className="bg-gradient-green">
                          <IoGiftOutline />
                        </span>
                        <div className="media-body">
                          <div className="text-grey">My Rewards Bonus </div>
                          <div className="mt-1 fs-4 fw-bold">
                            {" "}
                            {userData?.reward ? userData?.reward : 0} USDT
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-sm-6">
                  <div
                    className="card_image_bg widget-stat card "
                    //style={{ background: "#7445cc" }}
                  >
                    <div className="custom-padding">
                      <div className="media">
                        <span className="bg-gradient-green">
                          <IoWalletOutline />
                        </span>
                        <div className="media-body">
                          <div className="text-grey">
                            Register Wallet Address
                          </div>
                          <div className="mt-1 fs-4 fw-bold">
                            {walletAddress?.slice(0, 6)}...
                            {walletAddress?.slice(-6)}
                            <CopyToClipboard
                              text={`${walletAddress}`}
                              onCopy={copyWallet}
                            >
                              {isCopiedWallet ? (
                                <GiConfirmed
                                  size={20}
                                  className="ms-2 text-grey"
                                />
                              ) : (
                                <IoCopyOutline
                                  size={20}
                                  className="ms-2 text-grey"
                                />
                              )}
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Home;
