import React from 'react'
import logo from "../../../Asset 2.png";
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { getMailData } from '../../../helpers/api';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { GetTokenDetails } from '../../../store/actions/AuthActions';
import { Loader } from '../Dashboard/Loader';

export const Plan = () => {
  
        const dispatch = useDispatch();
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const userId = userDetails?.userId;
        const [loader, setLoader] = useState(true);
        const [mail, setEmail] = useState('');
        const [error, setError] = useState(false);

        useEffect(() => {
            if (userId) {
                let formData = { userId: userId };
                getMailData(formData)
                    .then((res) => {
                         console.log(res,"res:::")
                        if (res?.status) { // Check if the response data is valid
                            setEmail(res);
                            setLoader(false);
                        } else {
                            throw new Error("Invalid data received");
                        }
                    })
                    .catch((err) => {
                        console.error("API call failed:", err);
                        setError(true); // Set error state to true if the API call fails
                        setLoader(false); // Hide loader if there's an error
                    });
            } else {
                setLoader(false); // Hide loader if no userId is found
            }
        }, [userId]);

        if (loader) {
            return <Loader />;
        }

        if (error) {
            return <Loader />;
        }


        return (
            <div>
                <div className='container' >

                    <div className="navbar-area">

                        <nav className="navbar navbar-expand-md navbar-light d-flex justify-content-between">
                            <Link to="/" className="logo">
                                <img
                                    src={logo}
                                    alt=""
                                    className="mCS_img_loaded"
                                    style={{ height: "55px" }}
                                />
                            </Link>

                        </nav>
                    </div>

                    <div className='row plan-row'>

                        <div className='col-md-4'>

                            <div className='card'>

                                <div className='card-body'>
                                    <h6 className=''>Plan A</h6>
                                    <h3 className=''>520 USDT</h3>
                                    <div className='about-text'>
                                        <ul className="features-text features-text-new ">
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>
                                                    Reg fees - 30 USDT
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i> Gas Fees - 20 USDT
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i> Level 1 - 130 USDT
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>  Level 2 - 20 USDT
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i> Level 3 - 30 USDT
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i> Level 4 - 40 USDT
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>  Level 5 - 50 USDT
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i> Level 6 - 200 USDT
                                                </span>
                                            </li>


                                        </ul>
                                    </div>
                                    <Link to={!mail?.plan1
                                        ?.paid_30_dollar
                                        ? "/registerDMT"
                                        : mail?.plan1
                                            ?.paid_30_dollar && !mail?.plan1
                                                ?.all_level_updgrade
                                            ? "/LevelUpgradeA"
                                            : "/dashboard"}


                                    >
                                        <button
                                            className="btn btn-success default-btn banner-btn mt-5 w-100"
                                            onClick={() => {
                                                if (mail?.plan1
                                                    ?.paid_30_dollar && mail?.plan1
                                                        ?.all_level_updgrade) {
                                                    dispatch(GetTokenDetails({ value: 1, name: `Plan 520` }));
                                                }

                                            }}
                                        >
                                            {!mail?.plan1
                                                ?.paid_30_dollar
                                                ? "Choose Here"
                                                : mail?.plan1
                                                    ?.paid_30_dollar && !mail?.plan1
                                                        ?.all_level_updgrade
                                                    ? "Upgrade Level"
                                                    : "Dashboard"}
                                        </button>
                                    </Link>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card'>

                                <div className='card-body'>
                                    <h6 className=''>Plan B</h6>
                                    <h3 className=''>300 USDT</h3>
                                    <div className='about-text'>
                                        <ul className="features-text features-text-new  ">
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>
                                                    Reg fees - 30 USDT

                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>  Gas Fees - 20 USDT
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>  <span className='ps-1'>  Level 1 -  75 USDT </span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double mr"></i>  <span className='ps-1'>  Level 2 -  10   USDT</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>  <span className='ps-1'> Level 3 -    15 USDT </span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>  Level 4 - 20 USDT
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>  Level 5 -  30 USDT
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>  Level 6 -  100 USDT
                                                </span>
                                            </li>


                                        </ul>
                                    </div>
                                    <Link to={!mail?.plan2
                                        ?.paid_30_dollar
                                        ? "/registerDMTB"
                                        : mail?.plan2
                                            ?.paid_30_dollar && !mail?.plan2
                                                ?.all_level_updgrade
                                            ? "/LevelUpgradeB"
                                            : "/dashboard"}>
                                        <button
                                            className="btn btn-success default-btn banner-btn mt-5 w-100"
                                            onClick={() => {
                                                if (mail?.plan2
                                                    ?.paid_30_dollar && mail?.plan2
                                                        ?.all_level_updgrade) {
                                                    dispatch(GetTokenDetails({ value: 2, name: `Plan 280` }));
                                                }

                                            }}
                                        >
                                            {!mail?.plan2
                                                ?.paid_30_dollar
                                                ? "Choose Here"
                                                : mail?.plan2
                                                    ?.paid_30_dollar && !mail?.plan2
                                                        ?.all_level_updgrade
                                                    ? "Upgrade Level"
                                                    : "Dashboard"}
                                        </button>
                                    </Link>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>

                            <div className='card'>

                                <div className='card-body'>
                                    <h6 className=''>Plan C</h6>
                                    <h3 className=''>1000 USDT</h3>
                                    <div className='about-text'>
                                        <ul className="features-text features-text-new ">
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>
                                                    Reg fees - 30 USDT

                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>  Gas Fees -  20 USDT
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>  Level 1 - 250 USDT
                                                </span>
                                            </li>

                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>   Level 2 - 30 USDT
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>  Level 3 - 40 USDT
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>  Level 4 - 50 USDT
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>  Level 5 - 80 USDT
                                                </span>
                                            </li>
                                            <li>
                                                <span className=' '>
                                                    <i className="bx bx-check-double"></i>   Level 6 - 500 USDT
                                                </span>
                                            </li>


                                        </ul>
                                    </div>
                                    <Link to={!mail?.plan3
                                        ?.paid_30_dollar
                                        ? "/registerDMTC"
                                        : mail?.plan3
                                            ?.paid_30_dollar && !mail?.plan3
                                                ?.all_level_updgrade
                                            ? "/LevelUpgradeC"
                                            : "/dashboard"}>
                                        <button
                                            className="btn btn-success default-btn banner-btn mt-5 w-100"
                                            onClick={() => {
                                                if (mail?.plan3
                                                    ?.paid_30_dollar && mail?.plan3
                                                        ?.all_level_updgrade) {
                                                    dispatch(GetTokenDetails({ value: 3, name: `Plan 1000` }));
                                                }

                                            }}
                                        >
                                            {!mail?.plan3
                                                ?.paid_30_dollar
                                                ? "Choose Here"
                                                : mail?.plan3
                                                    ?.paid_30_dollar && !mail?.plan3
                                                        ?.all_level_updgrade
                                                    ? "Upgrade Level"
                                                    : "Dashboard"}
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

            </div>
        )
    }
