import React, { useState, useEffect } from "react";
import axios from "axios";
import "./chat.css";
import logo from "../../../dmt1.png"; // Import the static logo
import {
  chatHistory,
  chatUsers,
  markRead,
  myDonation,
  sendMessageApi,
  transactionApi,
} from "../../../helpers/api";
import { useSelector } from "react-redux";
// Import checkmark icons from react-icons
import { MdSend } from "react-icons/md";

const Chat = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { userToken } = useSelector((action) => action.auth);
  const userId = userDetails?.userId;
  const [activeChatId, setActiveChatId] = useState(null);
  const [typedMessage, setTypedMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    // Fetch chat users when the component mounts
    const fetchChatUsers = async () => {
      const chatUsersList = await chatUsers({ userId });
      setUsers(chatUsersList);
    };

    fetchChatUsers();
  }, [userId]);

  useEffect(() => {
    // Fetch chat history when the active chat changes
    const fetchChatHistory = async () => {
      if (activeChatId) {
        const history = await chatHistory({
          senderId: userId,
          receiverId: activeChatId,
        });
        setChatMessages(history);
      }
    };

    // Call fetchChatHistory immediately on component mount or activeChatId change
    fetchChatHistory();

    // Set interval to call fetchChatHistory every 30 seconds
    const interval = setInterval(fetchChatHistory, 30000);

    // Clear interval on component unmount or when activeChatId changes
    return () => clearInterval(interval);
  }, [activeChatId, userId, refresh]);

  const sendMessage = async () => {
    if (typedMessage.trim() !== "" && activeChatId) {
      const messageData = {
        senderId: userId,
        receiverId: activeChatId,
        message: typedMessage,
      };

      // Call the API to send the message
      const response = await sendMessageApi(messageData);
      if (response.status) {
        setChatMessages([
          ...chatMessages,
          { senderId: userId, message: typedMessage, isRead: false }, // Adjusted structure, set isRead as false initially
        ]);
        setTypedMessage(""); // Clear the input after sending
        setRefresh(!refresh);
      }
    }
  };

  const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
  const walletAddress = walletDetails?.wallet;
  const [apidata, setApidata] = useState([]);

  const [loading, setLoading] = useState(false);

  function getLevelData() {
    let fData = {
      packageId: userToken?.value,
      sender: walletAddress,
    };
    myDonation(fData).then((res) => {
      console.log(res, "res::cdjjdj");

      setApidata(res?.data);
      setLoading(true);
    });
  }

  useEffect(() => {
    getLevelData();
  }, [walletAddress, userToken]);

  return (
    <div className="chat-container">
      {/* Sidebar with user IDs */}
      <div className="chat-window">
        {activeChatId ? (
          <div className="chat-box">
            <div className="messages">
              {chatMessages.map((msg, index) => (
                <div
                  key={index}
                  className={`message ${
                    msg.senderId === userId ? "sent" : "received"
                  } ${msg.isRead ? "read" : "unread"}`}
                >
                  <div className="imgwrapper">
                    <img src={logo} alt="avatar" className="avatar" />
                  </div>
                  <div className="message-content">
                    <span className="user-id">{msg.senderId}</span>
                    <p className="mb-0">{msg.message}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Input box to type message */}
            <div className="p-3">
              {" "}
              <div className="input-group">
                <input
                  type="text"
                  className="form-control py-2 "
                  placeholder="Type a message..."
                  value={typedMessage}
                  onChange={(e) => setTypedMessage(e.target.value)} 
                />
                <span className="input-group-text bg-dgreen cursor-pointer">
                  {" "}
                  <div onClick={sendMessage}>
                    Send
                    <MdSend className="ms-1" />
                  </div>
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-chat-found">
            <div className="project-box border-success p-3">
              <span className=""> Select a user to chat with</span>
            </div>
          </div>
        )}
      </div>
      <div className="user-list">
        <h3 className="heading">Chats User</h3>
        <hr className="divider" />
        {users?.length > 0
          ? users?.map((user) => (
              <div
                className={`select-user ${
                  activeChatId === user.receiverId ? "active" : ""
                }`}
              >
                <div
                  key={user.receiverId}
                  className="user-id"
                  onClick={() => setActiveChatId(user.receiverId)}
                >
                  <img src={logo} alt="avatar" className="avatar" />
                  {user.receiverId}
                </div>
              </div>
            ))
          : null}

        <div className="mt-5">
          <h6 className="h6 fw-bold">Upline User ID</h6>

          {apidata?.length > 0 ? (
            apidata?.map((user, i) => {
              return (
               <div className={`select-user ${
                activeChatId === user.receiverId ? "active" : ""
              }`}>
                 <div
                  key={user.userId} // Use 'user' here instead of 'res'
                  className={`user-id 
                  }`}
                  onClick={() => setActiveChatId(user.userId)}
                >
                  <img src={logo} alt="avatar" className="avatar" />
                  {user.userId}
                </div>
               </div>
              );
            })
          ) : (
            <div className="card border">
              <div className="card-body">
                <span className="text-muted">No user found</span>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Chat window */}
    </div>
  );
};

export default Chat;
