import { LuBoxes } from "react-icons/lu";
import { BiMoneyWithdraw, BiDonateHeart } from "react-icons/bi";
import { VscDashboard } from "react-icons/vsc";
import { GrChat, GrGroup } from "react-icons/gr";
import { AiOutlineTeam, AiOutlineGift, AiOutlineUsergroupAdd } from "react-icons/ai";
import { FaTree, FaHandsHelping, FaHistory } from "react-icons/fa";
import { FcOnlineSupport } from "react-icons/fc";
import { RiFeedbackLine } from "react-icons/ri";
import Feedback from "react-bootstrap/esm/Feedback";
import { IoChatboxEllipsesOutline } from "react-icons/io5";

export const MenuList = [
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <VscDashboard size={30} color="#fff" />,
    to: "/dashboard",
  },
  {
    title: "Referral Range",
    classsChange: "mm-collapse",
    iconStyle: <AiOutlineUsergroupAdd size={30} />,
    to: "/levelReport",
  },
  {
    title: "Level Report",
    classsChange: "mm-collapse",
    iconStyle: <GrGroup size={30} />,
    to: "/levelWisereport",
  },
  {
    title: "Tree Structure",
    classsChange: "mm-collapse",
    iconStyle: <FaTree size={30} />,
    to: "/treeStructure",
  },
  {
    title: "Downline Team",
    classsChange: "mm-collapse",
    iconStyle: <AiOutlineTeam size={30} />,
    to: "/myteam",
  },
  {
    title: "Received Donation",
    classsChange: "mm-collapse",
    iconStyle: <FaHandsHelping size={30} color="#fff" />,
    to: "/Level",
  },
  {
    title: "Reward Bonus",
    classsChange: "mm-collapse",
    iconStyle: <AiOutlineGift size={30} color="#fff" />,
    to: "/reward",
  },
  {
    title: "Reward Status",
    classsChange: "mm-collapse",
    iconStyle: <BiDonateHeart size={30} />,
    to: "/rewardStatus",
  },
  {
    title: "Chat History",
    classsChange: "mm-collapse",
    iconStyle: <IoChatboxEllipsesOutline size={30} />,
    to: "/chat",
  },
  {
    title: "Feedback",
    classsChange: "mm-collapse",
    iconStyle: <GrChat size={30} />,
    to: "/feedback",
  },
];
