import React, { useContext, useState, useEffect, useRef } from "react";

import { CgProfile } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { GetTokenDetails } from "../../../store/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackages } from "../../../helpers/api";
import { FaRegUser } from "react-icons/fa";

const Header = () => {
  const { userToken } = useSelector((action) => {
    return action.auth;
  });
  const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
  const walletAddress = walletDetails?.wallet;
  const [selectedOption, setSelectedOption] = useState("");
  const [allPackData, setAllPackData] = useState("");

  const dispatch = useDispatch();

  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const email = userDetails?.email;
  const userId = userDetails?.userId;
  const popoverRef = useRef(null);
  const handleLogout = () => {
    localStorage.removeItem("selectedPackage");
    localStorage.removeItem("walletDetails"); // Clear user details from local storage
    localStorage.removeItem("userDetails"); // Clear user details from local storage
    navigate("/login");
  };

  useEffect(() => {
    // Initialize the popover using Bootstrap's JavaScript API
    const popover = new window.bootstrap.Popover(popoverRef.current, {
      container: "body",
      html: true,
      placement: "bottom",
      boundary: "viewport",
      fallbackPlacements: ["top", "left", "right"],
      content: `      
        <div class="">
          <div class="py-2 fw-bold">${email}</div>
          <div><a href="/logindashboard" class="py-1">Switch to All User Board</a></div>
          <hr/>
          <div class="d-grid"><a href="" class="btn logout-btn py-2">Logout</a></div>      
        </div>
      `,
    });

    // Handle the click event for the logout button inside the popover
    document.addEventListener("click", (e) => {
      if (e.target.classList.contains("logout-btn")) {
        handleLogout();
      }
    });

    return () => {
      popover.dispose();
    };
  }, [email, walletAddress]);

  useEffect(() => {
    // Load the selected package from localStorage on component mount
    const storedTokenDetails = JSON.parse(
      localStorage.getItem("selectedPackage")
    );
    if (storedTokenDetails) {
      dispatch(GetTokenDetails(storedTokenDetails));
      setSelectedOption(storedTokenDetails.value);
    }
  }, [dispatch]);

  useEffect(() => {
    // Fetch all packages
    getAllPackages(userId).then((res) => setAllPackData(res));
  }, [userId]);

  useEffect(() => {
    if (!userToken?.value && allPackData.length > 0) {
      // Automatically select the first package if none is selected
      const firstPackage = allPackData[0];
      const firstPackageDetails = {
        value: firstPackage?.packageId,
        name: `Plan ${firstPackage?.packageId}`,
      };
      dispatch(GetTokenDetails(firstPackageDetails));
      setSelectedOption(firstPackage?.packageId);
      localStorage.setItem(
        "selectedPackage",
        JSON.stringify(firstPackageDetails)
      );
    }
  }, [allPackData, userToken, dispatch]);

  const handleOptionChange = (event) => {
    const { value, selectedOptions } = event.target;
    const selectedPackage = {
      value,
      name: selectedOptions[0].text,
    };
    dispatch(GetTokenDetails(selectedPackage));
    setSelectedOption(value);
    localStorage.setItem("selectedPackage", JSON.stringify(selectedPackage));
  };

  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                <span className="text-success">Congratulation!</span>{" "}
                <span className="fw-bold">{userId} </span> 🎉
              </div>
            </div>
            <div className="navbar-nav header-right">
              <div className="nav-item d-flex align-items-center">
                <div className="me-2 pointer-cursor">
                  <div
                    ref={popoverRef}
                    className="user_icon"
                    data-bs-container="p-0"
                    data-bs-toggle="popover"
                    data-bs-placement="bottom"
                    data-bs-content="Bottom popover"
                  >
                    <FaRegUser size={18} />
                  </div>
                </div>
                <div className="">
                  <select
                    className="form-select custom-form-select"
                    value={userToken?.value}
                    onChange={(e) => {
                      handleOptionChange(e);
                    }}
                  >
                    {allPackData &&
                      allPackData.map((res, i) => {
                        return (
                          <option key={i} value={res?.packageId}>
                            Plan{" "}
                            {res?.packageId == 1
                              ? "520"
                              : res?.packageId == 2
                              ? "300"
                              : res?.packageId == 3
                              ? "1000"
                              : ""}{" "}
                            (USDT)
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
