import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  checkrewardCriteria,
  getDashBoardDetails,
  getLevelTeam,
  getNewLevelData,
} from "../../../helpers/api";
import moment from "moment/moment";
import { Pagination } from "@mui/material";
import { Loader } from "../Dashboard/Loader";
import Select from "react-select";

const LevelWiseFilter = () => {
  const options2 = [
    { value: "1", label: "Level 1" },
    { value: "2", label: "Level 2" },
    { value: "3", label: "Level 3" },
    { value: "4", label: "Level 4" },
    { value: "5", label: "Level 5" },
    { value: "6", label: "Level 6" },
  ];

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return date.toLocaleString("en-GB", options);
  };

  const [selectedOption, setSelectedOption] = useState(options2[0]);
  const { userToken } = useSelector((state) => state.auth);
  const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
  const walletAddress = walletDetails?.wallet;
  const [apidata, setApidata] = useState([]);
  const [levelData, setLevelData] = useState([]);
  const [steps, setSteps] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState("");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userId = userDetails?.userId;
  const [userData, setUserData] = useState("");

  useEffect(() => {
    const formData = {
      packageId: userToken?.value,
      wallet_address: walletAddress,
    };

    getDashBoardDetails(formData).then((res) => {
      setUserData(res);
    });
  }, [userId, walletAddress, userToken]);
  const getLevelData = (wallet, page, level, token) => {
    setLoading(true);
    getLevelTeam(wallet, level, page, token).then((res) => {
      setTotal(res?.totalTeam);
      setLevelData(res?.res);
      setLoading(false);
    });
  };

  useEffect(() => {
    getLevelData(walletAddress, page, selectedOption?.value, userToken?.value);
  }, [walletAddress, page, selectedOption, userToken]);

  const handleChange = (e, p) => {
    setPage(p);
    getLevelData(walletAddress, p, selectedOption?.value, userToken?.value);
  };
  useEffect(() => {
    let fData = {
      user: walletAddress,
      packageId: userToken.value,
    };
    checkrewardCriteria(fData).then((res) => {
      setApidata(res?.data?.allleveldata);
      setLoading(false);
    });
  }, [walletAddress, userToken]);

  useEffect(() => {
    const fetchTimer = () => {
      let formData = {
        user: walletAddress,
        packageId: userToken.value,
        time: userData.rewardDistributeTime,
      };
      getNewLevelData(formData)
        .then((res) => {
          setNewData(res?.data);
        })
        .catch((err) => {
          console.error("Error fetching timer:", err);
        });
    };

    fetchTimer();
  }, [walletAddress, userToken, userData]);
  console.log(total, "total::::");
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Tab.Container defaultActiveKey="Buy">
          <div className="row">
            <div className="col-xl-12">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="heading">Level Wise Report</h4>
                <div className="d-flex flex-wrap--">
                  <div className="ps-1 pe-1">
                    <Select
                      options={options2}
                      value={selectedOption}
                      onChange={(selected) => {
                        setSelectedOption(selected);
                        setPage(1);
                      }}
                      className=" custom-react-select ms-4 me-2"
                    />
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <Tab.Content>
                    <Tab.Pane eventKey="Buy">
                      <Tab.Container defaultActiveKey="BTH">
                        <Tab.Content>
                          <Tab.Pane eventKey="BTH">
                            <div className="table-responsive dataTablehistory">
                              <table
                                className="table dataTable table-hover display"
                                style={{ minWidth: "845px" }}
                              >
                                <thead>
                                  <tr>
                                    <th className="">User Address</th>
                                    <th className="">User Id</th>
                                    <th className="">Level</th>
                                    <th className="">Direct Member</th>
                                    <th className="">Email</th>
                                    <th className="">Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {levelData?.length > 0 ? (
                                    levelData?.map((data, ind) => {
                                      return (
                                        <tr key={ind}>
                                          <td>
                                            {data?.address?.slice(0, 6)}...
                                            {data?.address?.slice(-5)}
                                          </td>
                                          <td>
                                            <strong>{data?.userId}</strong>
                                          </td>
                                          <td>{data?.level + 1}</td>
                                          <td>{data?.direct_member}</td>
                                          <td>{data?.email}</td>
                                          <td>
                                            <span className="text-grey">
                                              {formatDate(data?.time)}
                                            </span>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={7}>
                                        <div className="text-center mt-4 mb-2 fw-bold fs-5 dash-heading">
                                          No Data Found.
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                              <div className="d-sm-flex text-center border-top justify-content-center align-items-center mt-3 mb-3">
                                <div className="dataTables_paginate border-top paging_simple_numbers mb-0 pt-3">
                                  <Pagination
                                    color="primary"
                                    count={Math.ceil(total / 10)}
                                    page={page}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className="mt-5 ">
                                {selectedOption?.value === "1" ? (
                                  <>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="project-box bg-light-orange">
                                          <h6 className="card-title mb-3">
                                            Level 1st Part Wise Team{" "}
                                          </h6>

                                          <div className="">
                                            <div className="">
                                              {/* <h6 className="text-success">
                                              Total Members
                                            </h6> */}
                                              <div className="d-flex gap-5">
                                                <div>
                                                  <span className="me-2">
                                                    A part:
                                                  </span>{" "}
                                                  <strong>
                                                    {newData[0]?.[0]?.part1} / 1
                                                  </strong>
                                                </div>
                                                <div>
                                                  <span className="me-2">
                                                    B part:
                                                  </span>{" "}
                                                  <strong>
                                                    {" "}
                                                    {newData[0]?.[1]?.part2} / 1
                                                  </strong>
                                                </div>
                                                <div>
                                                  <span className="me-2">
                                                    C part:
                                                  </span>{" "}
                                                  <strong>
                                                    {" "}
                                                    {newData[0]?.[2]?.part3} / 1
                                                  </strong>
                                                </div>
                                                <div>
                                                  <span className="me-2">
                                                    D part:
                                                  </span>{" "}
                                                  <strong>
                                                    {" "}
                                                    {newData[0]?.[3]?.part4} / 1
                                                  </strong>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="project-box bg-light-green">
                                          <h5 className="card-title mb-3">
                                            Level 1st Total Team{" "}
                                          </h5>
                                          <div className="d-flex gap-5">
                                            <h6 className="mb-0">
                                              <span className="me-2">
                                                {" "}
                                                Total Member :
                                              </span>{" "}
                                              <strong>{4}</strong>
                                            </h6>
                                            <h6 className="mb-0">
                                              <span className="me-2">
                                                {" "}
                                                Avaialble Member :{" "}
                                              </span>
                                              <strong>
                                                {" "}
                                                {total ? 4 - total : 0}
                                              </strong>
                                            </h6>
                                            <h6 className="mb-0">
                                              <span className="me-2">
                                                {" "}
                                                Completed Member :
                                              </span>{" "}
                                              <strong>
                                                {" "}
                                                {total ? total : 0}
                                              </strong>
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              {selectedOption?.value === "2" ? (
                                <>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="project-box bg-light-orange">
                                        <h5 className="card-title mb-3">
                                          Level 2nd PartWise Team{" "}
                                        </h5>

                                        <div className="">
                                          {/* <h6 className="text-success">
                                              Total Members
                                            </h6> */}
                                          <div className="d-flex gap-5">
                                            <div>
                                              <strong>A part:</strong>{" "}
                                              {newData[1]?.[0]?.part1} / 4
                                            </div>
                                            <div>
                                              <strong>B part:</strong>{" "}
                                              {newData[1]?.[1]?.part2} / 4
                                            </div>
                                            <div>
                                              <strong>C part:</strong>{" "}
                                              {newData[1]?.[2]?.part3} / 4
                                            </div>
                                            <div>
                                              <strong>D part:</strong>{" "}
                                              {newData[1]?.[3]?.part4} / 4
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-6">
                                      <div className="project-box bg-light-green">
                                        <h5 className="card-title mb-3">
                                          Level 2nd Total Team{" "}
                                        </h5>

                                        <div className="d-flex gap-5">
                                          <h6 className="mb-0">
                                            Total Member :{" "}
                                            <strong> {16}</strong>
                                          </h6>
                                          <h6 className="mb-0">
                                            Available Member :{" "}
                                            <strong>
                                              {" "}
                                              {total ? 16 - total : 0}
                                            </strong>
                                          </h6>
                                          <h6 className="mb-0">
                                            Completed Member :{" "}
                                            <strong>{total ? total : 0}</strong>
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {selectedOption?.value === "3" ? (
                                <>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="project-box bg-light-orange">
                                        <h5 className="card-title mb-3">
                                          Level 3rd PartWise Team{" "}
                                        </h5>

                                        {/* <h6 className="text-success">
                                        Total Members
                                      </h6> */}
                                        <div className="d-flex gap-5">
                                          <div>
                                            <span>A part:</span>{" "}
                                            <strong>
                                              {" "}
                                              {newData[2]?.[0]?.part1} / 16
                                            </strong>
                                          </div>
                                          <div>
                                            <span>B part:</span>{" "}
                                            <strong>
                                              {newData[2]?.[1]?.part2} / 16
                                            </strong>
                                          </div>
                                          <div>
                                            <span>C part:</span>{" "}
                                            <strong>
                                              {newData[2]?.[2]?.part3} / 16
                                            </strong>
                                          </div>
                                          <div>
                                            <span>D part:</span>{" "}
                                            <strong>
                                              {newData[2]?.[3]?.part4} / 16
                                            </strong>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-6">
                                      <div className="project-box bg-light-green">
                                        <h5 className="card-title mb-3">
                                          Level 3rd Total Team{" "}
                                        </h5>
                                        <div className="d-flex gap-5">
                                          <h6 className="mb-0">
                                            Total Member :<strong> {64}</strong>
                                          </h6>
                                          <h6 className="mb-0">
                                            Available Member :{" "}
                                            <strong>
                                              {total ? 64 - total : 0}
                                            </strong>
                                          </h6>
                                          <h6 className="mb-0">
                                            Completed Member :{" "}
                                            <strong>{total ? total : 0}</strong>
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              {selectedOption?.value === "4" ? (
                                <>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="project-box bg-light-orange">
                                        <h5 className="card-title mb-3">
                                          Level 4th PartWise Team{" "}
                                        </h5>

                                        {/* <h6 className="text-success">
                                        Total Members
                                      </h6> */}
                                        <div className="d-flex gap-5">
                                          <div>
                                            <span>A part:</span>{" "}
                                            <strong>
                                              {newData[3]?.[0]?.part1} / 64
                                            </strong>
                                          </div>
                                          <div>
                                            <span>B part:</span>{" "}
                                            <strong>
                                              {" "}
                                              {newData[3]?.[1]?.part2} / 64
                                            </strong>
                                          </div>
                                          <div>
                                            <span>C part:</span>{" "}
                                            <strong>
                                              {newData[3]?.[2].part3} / 64
                                            </strong>
                                          </div>
                                          <div>
                                            <span>D part:</span>{" "}
                                            <strong>
                                              {newData[3]?.[3]?.part4} / 64
                                            </strong>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-6">
                                      <div className="project-box bg-light-green">
                                        <h5 className="card-title mb-3">
                                          Level 4th Total Team{" "}
                                        </h5>
                                        <div className="d-flex gap-5">
                                          <div className="">
                                            Total Member :{" "}
                                            <strong>{256}</strong>
                                          </div>
                                          <div className="">
                                            Available Member :{" "}
                                            <strong>
                                              {" "}
                                              {total ? 256 - total : 0}
                                            </strong>
                                          </div>
                                          <div className="">
                                            Completed Member :{" "}
                                            <strong>{total ? total : 0}</strong>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {selectedOption?.value === "5" ? (
                                <>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="project-box bg-light-orange">
                                        <h5 className="card-title mb-3">
                                          Level 5th PartWise Team{" "}
                                        </h5>

                                        {/* <h6 className="text-success">
                                        Total Members
                                      </h6> */}
                                        <div className="d-flex gap-5">
                                          <li>
                                            <span>A part:</span>{" "}
                                            {newData[4]?.[0]?.part1} / 256
                                          </li>
                                          <li>
                                            <span>B part:</span>{" "}
                                            {newData[4]?.[1]?.part2} / 256
                                          </li>
                                          <li>
                                            <span>C part:</span>{" "}
                                            {newData[4]?.[2]?.part3} / 256
                                          </li>
                                          <li>
                                            <span>D part:</span>{" "}
                                            {newData[4]?.[3]?.part4} / 256
                                          </li>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-6">
                                      <div className="project-box bg-light-green">
                                        <h5 className="card-title mb-3">
                                          Level 5th Total Team{" "}
                                        </h5>
                                      <div className="d-flex gap-5">
                                      <div>
                                          Total Member : <strong>{1024}</strong>
                                        </div>
                                        <div>
                                          Available Member :{" "}
                                         <strong> {total ? 1024 - total : 0}</strong>
                                        </div>
                                        <div>
                                          Completed Member : <strong>{total ? total : 0}</strong>
                                        </div>
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              {selectedOption?.value === "6" ? (
                                <>

<div className="row">
                                    <div className="col-lg-6">
                                      <div className="project-box bg-light-orange">
                                  <h5 className="card-title mb-3">
                                    Level 6th PartWise Team{" "}
                                  </h5>
                                 
                              
                                      {/* <h6 className="text-success">
                                        Total Members
                                      </h6> */}
                                      <div className="d-flex gap-5">
                                        <div>
                                          <span>A part:</span>{" "}
                                          {newData[5]?.[0].part1} / 1024
                                        </div>
                                        <div>
                                          <span>B part:</span>{" "}
                                          {newData[5]?.[1].part2} / 1024
                                        </div>
                                        <div>
                                          <span>C part:</span>{" "}
                                          {newData[5]?.[2].part3} / 1024
                                        </div>
                                        <div>
                                          <span>D part:</span>{" "}
                                          {newData[5]?.[3].part4} / 1024
                                        </div>
                                      </div>
                                      </div>
                                    </div>
                                
                                    <div className="col-lg-6">
                                    <div className="project-box bg-light-green">
                                  <h5 className="card-title mb-3">
                                    Level 6th Total Team{" "}
                                  </h5>
                                  <div className="d-flex gap-5">
                                <div>
                                    Total Mmeber : <strong> {4096}</strong>
                                  </div>
                                  <div>
                                    Available Mmeber :{" "}
                                   <strong> {total ? 4096 - total : 0}</strong>
                                  </div>
                                  <div>
                                    Completed Mmeber : <strong>{total ? total : 0}</strong>
                                  </div>
                                  </div>
                                  </div>
                                  </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </div>
          </div>
        </Tab.Container>
      )}
    </div>
  );
};

export default LevelWiseFilter;
