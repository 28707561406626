import React from "react";
import img from "./Certificate-new.png";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const Certificate = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userId = userDetails?.userId || "dmt333232"; // Example parent ID

  return (
    <div className="">
      {/* <h4 className="heading">Certificate</h4> */}

      <div className="col-lg-7 m-auto pt-3">
        <div className="position-relative">
          {/* Certificate image */}
          <div className="card shadow border-0">
            <img src={img} alt="Certificate" className="img-fluid" />

            {/* Stylish Message */}
            <div className="tree-overlay">
              <div className="parent-id">
                <h5 className="mb-0">
                 <span className="stylish-font"> Dear </span> <span className="highlight">{userId}</span>,
                </h5>
                <div className="mb-3">
                 <span className=" stylish-font "> Now you are a </span> <span className="highlight"> member</span> <span className=" stylish-font "> of
                  the </span> <span className="highlight"> DMT Club.</span>
                </div>
                <Link to="/donationList">
                  <button className="btn-action py-2">
                    My Donation <MdKeyboardArrowRight size={20} />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Custom CSS for overlay positioning */}
      <style jsx>{`
        .certificate-container {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }

        .certificate-image {
          display: block;
          margin: 0 auto;
          width: 80%;
          height: auto;
        }

        .tree-overlay {
          position: absolute;
          top: 40%;
          left: 18%;
          /* transform: translate(-50%, -50%); */
          /* text-align: center; */
          width: 80%;
        }

        .parent-id h5 {
          font-size: 1.5rem;
          color: #000;
          margin-bottom: 20px;
          font-weight: 600;
        }

        .highlight {
          color: #b9a14b;
          font-weight: 900;
          font-size: 1.6rem;
            
        }

        .stylish-font {
        font-family: "Style Script", cursive;
        font-size: 2rem;
        }

        @media (max-width: 768px) {
          .parent-id h5 {
            font-size: 1.1rem;
          }

          .highlight {
            font-size: 1.2rem;
          }

          .tree-overlay {
            bottom: 50px;
          }

          .certificate-image {
            width: 100%;
            height: auto;
          }
            .tree-overlay {
    
    left: 10%;
      width: 60%;
      // background:red
    
      }

      .highlight {
   
    font-weight: 500;
    font-size: 1.1rem;
}
    .stylish-font {
  
    font-size: 1.1rem;
}
        }
      `}</style>
    </div>
  );
};

export default Certificate;
