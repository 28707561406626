import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetTokenDetails, userExistAction } from "../../store/actions/AuthActions";
import { getTimer, getMailData, expDonationList } from "../../helpers/api";
import {
  getBalanceUser,
  isUserExist,
  upgradeLevelMember,
  getUserData,
  upgardeSixLevel,
  getUserLevelStatus,
  getPackageExists,
} from "../../helpers/getWeb3";
import { USDT_Token_ADDRESS } from "../../helpers/config";
import logo from "../../blacklogo.png";
import CountdownTimer from "./CountDownTimer.jsx"; // Import CountdownTimer
import { TiTick } from "react-icons/ti";
import { RxCrossCircled } from "react-icons/rx";
import toast from "react-hot-toast";
import ConnectButtons from "../components/Button/ConnectButton.jsx";
import { Loader } from "../components/Dashboard/Loader.jsx";
function LevelPlanB() {
  const { walletAddress } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const email = userDetails?.email;
  const userId = userDetails?.userId;
  const [loader, setLoader] = useState(false);

  const [loading, setLoading] = useState(false);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [timerTimestamp, setTimerTimestamp] = useState(0);
  const [userData, setUserData] = useState([]);
  const [mailData, setMailData] = useState("");
  const navigate = useNavigate();
  const [packageExist, setPackageExist] = useState(false);

  useEffect(() => {
    async function fetchMailData() {
      let formData = { userId: userId };
      const res = await getMailData(formData);
      setMailData(res?.plan1);
     
    }
    fetchMailData();
  }, [userId]);

  useEffect(() => {
    const fetchTimer = () => {
      let formData = { userId: mailData?.userId, packageId: 2 };
      getTimer(formData)
        .then((res) => {
          setTimerTimestamp(res?.date || 0);
        })
        .catch((err) => {
          console.error("Error fetching timer:", err);
        });
    };

    fetchTimer(); // Initial call

    const interval = setInterval(fetchTimer, 10000); // Call every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [mailData]);

  useEffect(() => {
    async function fetchBalance() {
      const res = await getBalanceUser(walletAddress, USDT_Token_ADDRESS);
      const balance = Number(res?.value / 1e18) || 0;
      setTokenBalance(balance);
    }
    fetchBalance();
  }, [walletAddress]);

  useEffect(() => {
    async function checkUserExistence() {
      const res = await isUserExist(walletAddress);
      dispatch(userExistAction(res));
    }
    checkUserExistence();
  }, [refresh, walletAddress, dispatch]);


  async function registerApp(amount) {
    try {
      console.log(packageExist,"packageExist")
      const exists = await isUserExist(walletAddress);

      if (!exists) {
        toast.error("Wallet does not exist!");
        return;
      }
      if (packageExist) {
        toast.error("Donation already done!");
        return;
      }
    
      const walletAddressLower = walletAddress?.toLowerCase();
      const mailAddressLower = mailData?.wallet_address?.toLowerCase();

      if (walletAddressLower === mailAddressLower) {
        await upgradeLevelMember(
          walletAddress,
          amount,
          USDT_Token_ADDRESS,
          setLoading,
          // level,
          2,
          () => {
            setRefresh(!refresh)
          },
          navigate
        );
      } else {
        toast.error("Register wallet and current wallet address do not match!");
      }
    } catch (error) {
      console.error("An error occurred during registration:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  }

  useEffect(() => {
    let formData = {
      userId: userId,

    };
    getMailData(formData).then((res) => {

      if (res?.plan1?.email_verified && res?.plan1?.paid_30_dollar) {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        userDetails.memberregister = true;
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
      } else if (res?.plan3?.email_verified && res?.plan2?.paid_30_dollar) {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        userDetails.memberregister = true;
        localStorage.setItem("userDetails", JSON.stringify(userDetails));

      } else if (res?.plan3?.email_verified && res?.plan3?.paid_30_dollar) {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        userDetails.memberregister = true;
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
      }
    });
  }, [userId]);

  useEffect(() => {

    let fData = {
      userId: mailData?.referrerId,
      packageId: 2
    }
    expDonationList(fData).then((res) => {
      setUserData(res?.minUpline)
      setLoader(false);
    })
    getUserLevelStatus(walletAddress, 2,6).then((res) => {

     
      setPackageExist(res)
    })
  }, [mailData,walletAddress,refresh])
  useEffect(()=>{
    dispatch(GetTokenDetails({ value: 2, name: `Plan 280` }));
  },[refresh])
  return (
    <div className="page_content gray_bg">
      <div className="container login_page_container">
        <div className="login_header-new">
          <div>
            <img src={logo} alt="" className="mCS_img_loaded" height={50} />
          </div>
          <div className="deal-of-the-day-content mb-3 mt-0 d-flex justify-content-center align-items-center">
            <CountdownTimer timestamp={timerTimestamp} />
          </div>
        </div>
        {loader ? (
          <Loader />
        ) : (
          <main className="login-page_wrapper">
            <div className="row my-5">
              <div className="col-lg-8 m-auto">
                <div className="login-card-new">
                  <div className="d-flex justify-content-between">
                    <h2 className="login_title mb-1">Upgrade Level</h2>
                    <ConnectButtons />
                  </div>
                  <p className="text_gray">Upgrade all levels within 72 hours</p>
                  <div className="d-flex justify-content-between">
                    <div className="text-endtext-white fs-6">
                      Balance:{" "}
                      <span className="text-info">
                        {tokenBalance?.toFixed(1)} USDT
                      </span>
                    </div>
                    <div className="text-endtext-white fs-6">
                      Referal Id:{" "}
                      <span className="text-info">{mailData?.referrerId} </span>
                    </div>
                  </div>
                  <div className="text-endtext-white pb-2 fs-6">
                    Register Wallet:{" "}
                    <span className="text-warning">
                      {mailData?.wallet_address?.slice(0, 10)}...
                      {mailData?.wallet_address?.slice(-10)}{" "}
                    </span>
                  </div>
                  <div className="cart-table table-responsive">
                    <table className="table table-bordered custom-table">
                      <thead>
                        <tr>
                          <th>Level</th>
                          <th>Name</th>
                          <th>Price</th>
                          <th> UserId</th>
                          <th>Address</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>6</td>
                          <td>Universe DMT</td>
                          <td> 100 USDT</td>
                          <td>{userData && userData.length > 5 ? userData[5].id : '...'}</td>
                          <td>
                            {userData && userData.length > 5
                              ? `${userData[5].address.slice(0, 6)}...${userData[5].address.slice(-6)}`
                              : '...'}
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Supernova DMT</td>
                          <td>30 USDT  </td>
                          <td>{userData && userData.length > 4 ? userData[4].id : '...'}</td>
                          <td>
                            {userData && userData.length > 4
                              ? `${userData[4].address.slice(0, 6)}...${userData[4].address.slice(-6)}`
                              : '...'}
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Galaxy DMT</td>
                          <td>20 USDT </td>
                          <td>{userData && userData.length > 3 ? userData[3].id : '...'}</td>
                          <td>
                            {userData && userData.length > 3
                              ? `${userData[3].address.slice(0, 6)}...${userData[3].address.slice(-6)}`
                              : '...'}
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Solar DMT</td>
                          <td>15 USDT </td>
                          <td>{userData && userData.length > 2 ? userData[2].id : '...'}</td>
                          <td>
                            {userData && userData.length > 2
                              ? `${userData[2].address.slice(0, 6)}...${userData[2].address.slice(-6)}`
                              : '...'}
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Planet DMT</td>
                          <td> 10 USDT</td>
                          <td>{userData && userData.length > 1 ? userData[1].id : '...'}</td>
                          <td>
                            {userData && userData.length > 1
                              ? `${userData[1].address.slice(0, 6)}...${userData[1].address.slice(-6)}`
                              : '...'}
                          </td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Star DMT</td>
                          <td> 75 USDT</td>
                          <td>{userData && userData.length > 0 ? userData[0].id : '...'}</td>
                          <td>
                            {userData && userData.length > 0
                              ? `${userData[0].address.slice(0, 6)}...${userData[0].address.slice(-6)}`
                              : '...'}
                          </td>
                        </tr>
                      </tbody>

                      
                    </table>
                    <div className="text-endtext-white pb-2 pt-1 fs-6">
                      Note :{" "}
                      <span className="text-warning">
                        250 USDT Donation Amount + 20 USDT Gas Fees
                      </span>
                    </div>
                  </div>
                  <button
  className="default-btn banner-btn w-100 mt-3"
  onClick={() => registerApp(270)}
  disabled={loading}
  aria-disabled={loading}
>
  {loading ? "Loading..." : "Donate Now 270 USDT"}
</button>

                </div>
              </div>
            </div>
          </main>
        )}
      </div>
    </div>
  );
}

export default LevelPlanB;
